import BottomButton from "@/components/BottomButton";
import familyPropertyClause from "@/components/documents/familyPropertyClause";
import familyPropertyDisclaimer from "@/components/documents/familyPropertyDisclaimer";
import familyPropertyNotice from "@/components/documents/familyPropertyNotice";
import { registerComponent } from "@/utils";
import agreement from "@/components/documents/agreement";
import message from "@/components/documents/message";

export const components = registerComponent({
  BottomButton,
  familyPropertyClause,
  familyPropertyDisclaimer,
  familyPropertyNotice,
  agreement,
  message
});

export default { components };
